import type Vue from 'vue'
import { RouteConfig } from 'vue-router'

export default function generateRoutes (app: Vue): RouteConfig[] {
  const routes = [{
    path: '/users',
    name: 'access-control.users',
    component: { render: (h: Function) => h('RouterView') },
    meta: {
      breadcrumbs: {
        text: 'user.global.users'
      }
    },
    children: [{
      path: ':view?',
      name: 'users.view',
      component: () => import(/* webpackChunkName: "access-control-views" */'../views/Users.vue'),
      props: true,
      meta: {
        breadcrumbs: {
          text: 'core.action.edit',
          to: null
        }
      }
    }]
  }, {
    path: '/access-control',
    component: { render: (h: Function) => h('RouterView') },
    meta: {
      breadcrumbs: {
        text: 'accessControl.global.headline',
        to: null
      }
    },
    children: [{
      path: ':view?',
      name: 'accessControl.view',
      component: () => import(/* webpackChunkName: "access-control-views" */'../views/AccessControl.vue'),
      props: true,
      meta: {
        breadcrumbs: {
          text: 'core.action.edit',
          to: null
        }
      }
    }]
  }, {
    path: '/user/:id/history',
    name: 'access-control.history',
    component: () => import(/* webpackChunkName: "access-control-views" */'../views/UserHistory.vue'),
    props: (route: any) => ({
      userId: route.params.id
    }),
    meta: {
      breadcrumbs: {
        text: 'user.global.users'
      }
    }
  }, {
    path: '/domain/settings/:view?',
    name: 'domain.edit',
    component: () => import(/* webpackChunkName: "access-control-views" */'../views/domain-settings/DomainSettings.vue'),
    props: true,
    meta: {
      breadcrumbs: {
        text: 'accessControl.domain.preferences'
      }
    }
  }] as RouteConfig[]

  if (app.$store.state.auth.user.is_super) {
    routes.push({
      path: '/domains',
      name: 'access-control.domains',
      component: () => import(/* webpackChunkName: "access-control-views" */'../views/DomainList.vue'),
      meta: {
        breadcrumbs: {
          text: 'accessControl.domain.domains'
        }
      }
    })
  }

  return routes
}
